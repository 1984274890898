import {
  useCompanyAssessmentQuery,
  useEsrsAssessmentYearQuery,
  useGetActionQuery,
  useGetMetricNameQuery,
  useGetPortfolioNameQuery,
  useGetTargetQuery,
  useGroupAssessmentQuery,
} from 'models';
import { useMemo } from 'react';
import {
  BreadcrumbsList,
  ESRS_PATHS_TO_IGNORE,
  ID_REGEX,
  LinkType,
  PAI_PATHS_TO_IGNORE,
  PATHS_TO_IGNORE,
  PORTFOLIOS_PATHS_TO_IGNORE,
} from './Breadcrumbs.utils';
import { useParams } from 'react-router-dom';
import { formatDisplayQuarterYear } from 'utils/date';

const portfolioOwnerContext = {
  headers: {
    'x-hasura-role': 'portfolio-owner',
  },
};

export const useGetPathName = (
  index: number,
  path: string,
  linkType: string,
  isEditTarget: boolean
) => {
  const {
    metricRef = '',
    cAssessmentId = '',
    portfolioId = '',
    esrsAssessmentId = '',
    gAssessmentId = '',
    targetId = '',
    reportingYear = '',
    reportingPeriod = '',
    actionId,
  } = useParams();

  const isAssessmentId = useMemo(
    () => [cAssessmentId, gAssessmentId, portfolioId, esrsAssessmentId].includes(path),
    [cAssessmentId, portfolioId, esrsAssessmentId]
  );

  const isGroup = useMemo(() => gAssessmentId !== '', [gAssessmentId]);

  const { data: taxonomyGroupAssessment } = useGroupAssessmentQuery({
    variables: { assessmentId: gAssessmentId },
    skip: path !== gAssessmentId,
  });
  const { data: taxonomyAssessment } = useCompanyAssessmentQuery({
    variables: { cAssessmentId },
    skip: path !== cAssessmentId,
  });
  const { data: esrsAssessment } = useEsrsAssessmentYearQuery({
    variables: { esrsAssessmentId },
    skip: path !== esrsAssessmentId,
  });
  const { data: portfolioName } = useGetPortfolioNameQuery({
    variables: { id: path },
    context: portfolioOwnerContext,
    skip: path !== portfolioId,
  });
  const { data: esrsTarget } = useGetTargetQuery({
    variables: { targetId: path },
    skip: path !== targetId || !isEditTarget,
  });
  const { data: esrsMetricName } = useGetMetricNameQuery({
    variables: { ref: path },
    skip: path !== metricRef || !path,
  });
  const { data: esrsAction } = useGetActionQuery({
    variables: { id: actionId },
    skip: !actionId,
  });

  const pageName = useMemo(() => {
    if (index === 0) return BreadcrumbsList[linkType].home;
    if (path === metricRef) {
      return esrsMetricName?.EsrsMetric[0].title;
    }
    if (path === reportingYear) {
      return formatDisplayQuarterYear(reportingYear);
    }
    if (path === reportingPeriod) {
      return formatDisplayQuarterYear(reportingPeriod);
    }
    if (isAssessmentId) {
      if (linkType === LinkType.Taxonomy && isGroup) {
        return taxonomyGroupAssessment?.assessment?.name;
      }
      if (linkType === LinkType.Taxonomy && !isGroup) {
        return taxonomyAssessment?.companyAssessment?.aggregate.title;
      }
      if (linkType === LinkType.Esrs) {
        return esrsAssessment?.esrsAssessment?.reportingYear;
      }
      if (linkType === LinkType.Pai) {
        return portfolioName?.portfolio?.name;
      }
      if (linkType === LinkType.Portfolios) {
        return portfolioName?.portfolio?.name;
      }
    }
    if (ID_REGEX.test(path) && isEditTarget) {
      return !!esrsTarget?.target?.name ? `${esrsTarget?.target?.name}` : 'Target';
    }
    if (ID_REGEX.test(path) && !!actionId) {
      return !!esrsAction?.esrs_Actions_by_pk?.title
        ? `${esrsAction?.esrs_Actions_by_pk?.title}`
        : 'Action';
    }
    return BreadcrumbsList[linkType][path] ?? path.charAt(0).toUpperCase() + path.slice(1);
  }, [
    taxonomyGroupAssessment,
    taxonomyAssessment,
    esrsAssessment,
    portfolioName,
    isAssessmentId,
    isEditTarget,
    path,
  ]);

  return pageName ?? '';
};

export const checkCurrentPath = (path: string) => ({
  isTaxonomy: path.includes('/assessments'),
  isEsrs: path.includes('/esrs'),
  isPai: path.includes('/pai/'),
  isEsrsStandard: path.includes('/standard'),
  isChart: path.includes('/chart'),
  isOnboarding: path.includes('onboarding'),
  isEditTarget: path.includes('/target') && path.includes('/edit'),
  isEditAction: path.includes('/action-form') && path.includes('/edit'),
  isEditTaxonomy: path.includes('/assessments') && path.includes('/edit'),
});

export const isValidPath = (
  path: string,
  {
    isEsrsStandard,
    isOnboarding,
    isChart,
    isEditTarget,
    isEditAction,
    isEditTaxonomy,
  }: {
    isEsrsStandard: boolean;
    isOnboarding: boolean;
    isChart: boolean;
    isEditTarget: boolean;
    isEditAction: boolean;
    isEditTaxonomy: boolean;
  },
  materialStandardId: string,
  reportingUnitId: string,
  linkType: string
) => {
  const taxonomyYearRegex = /Q[1-4]-\d{4}/;
  if (path === '' || PATHS_TO_IGNORE.includes(path)) {
    return false;
  }
  if (isEsrsStandard && ESRS_PATHS_TO_IGNORE.includes(path)) {
    return false;
  }
  if (linkType === LinkType.Portfolios && PORTFOLIOS_PATHS_TO_IGNORE.includes(path)) {
    return false;
  }
  if (linkType === LinkType.Pai && PAI_PATHS_TO_IGNORE.includes(path)) {
    return false;
  }
  if (isOnboarding && ID_REGEX.test(path)) {
    return false;
  }
  if (isEditTarget && path === 'target-form') {
    return false;
  }
  if (isChart && path === materialStandardId) {
    return false;
  }
  if (isEditAction && path === 'action-form') {
    return false;
  }
  if (isEditTaxonomy && taxonomyYearRegex.test(path)) {
    return false;
  }
  if (path === reportingUnitId) {
    return false;
  }
  return true;
};
